// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"
import Section from "../components/Section"

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <Section>
      <h6>404: Not Found</h6>
    </Section>
  </Layout>
)

export default NotFoundPage
